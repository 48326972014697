import React from 'react';

import '../styles/maps.scss';

import ChoczniaImg from '../images/chocznia.png';
import GalicyjskaImg from '../images/galicyjska.png';

export default function Maps() {
  return (
    <section className='page_map'>
      <div className="map_container">
        <div className="map_row" id='ContactAnchor'>
          <div className="map">
            <img src={ChoczniaImg} alt="" />
          </div>
          <div className="map_contact">
            <h3>Chocznia, ul. Tadeusza Kościuszki 250</h3>
            <ul className='timesheet_list'>
              <li className='timesheet_list--item'>Pon-Pt 9.00 - 21:00</li>
            </ul>
            <div className="contact_box">
              <p></p>
            </div>
          </div>
        </div>
        <div className="map_row">
          <div className="map_contact">
            <h3>Kraków, ul. Galicyjska 3c/3</h3>
            <ul className='timesheet_list'>
            <li className='timesheet_list--item'>Pon-Pt 9.00 - 21:00</li>
            </ul>
          </div>
          <div className="map">
            <img src={GalicyjskaImg} alt="" />
          </div>
        </div>
      </div>

    </section>
  )
}
