import React from 'react';

import '../styles/about.scss'

import Gallery1 from '../images/kasia-2.jpg';
import Gallery2 from '../images/kasia-3.png';
import Gallery3 from '../images/kasia-4.jpg';

export default function About() {
  return (
    <section className='page_about'>
      <div className="page_wrapper">
        <div className="about_container">
          <div className="about_description">
            <h2 className='section_heading' id='AboutAnchor'>
              Centrum YAS
              <br />
              Dr Katarzyna Kubasiak
            </h2>
            <p>
              Centrum YAS to miejsce, w którym oferuję Pacjentom najskuteczniejsze formy terapii i
              najwyższą jakość usług, zgodnie z aktualnymi doniesieniami ze świata medycyny i sportu.
              Odkrywanie tajników anatomii ciała ludzkiego to jedna z moich największych pasji oraz praca zawodowa. Oprócz pracy z pacjentami jestem nauczycielem akademickim przedmiotu anatomia człowieka i jego koordynatorem na trzech kierunkach studiów.
              To niezwykle pomocne, ponieważ obie dziedziny są ze sobą ścisłe związane, dzięki czemu elementy pracy dydaktycznej mogę przekładać na doskonalenie pracy w gabinecie. Obszerna wiedza anatomiczna umożliwia bowiem holistyczną pracę z pacjentem. Poznane techniki leczenia wykorzystuje zarówno w terapii układu ruchu jak również w zabiegach estetyczno terapeutycznych.
            </p>
          </div>
          <div className="about_gallery">
            <div>
              <img src={Gallery1} alt="" />
            </div>
            <div>
              <img src={Gallery2} alt="" />
            </div>
            <div className='about_gallery--wide'>
              <img src={Gallery3} alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
