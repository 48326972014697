import * as React from "react";
import '../styles/app.scss'

// Components
import Navbar from '../components/navbar';
import Header from '../components/header';
import About from '../components/about';
import Pricing from '../components/pricing';
import Reviews from '../components/reviews';
import Maps from '../components/maps';
import Footer from '../components/footer';

const IndexPage = () => {
  return (
    <main>
      <Navbar></Navbar>
      <Header></Header>
      <About></About>
      <Pricing></Pricing>
      <Reviews></Reviews>
      <Maps></Maps>
      {/* <Footer></Footer> */}
    </main>
  )
}

export default IndexPage
