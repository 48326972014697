import React from 'react';

import '../styles/pricing.scss';

import Gallery1 from '../images/kasia-2.jpg';
import Gallery2 from '../images/kasia-3.png';
import Gallery3 from '../images/kasia-4.jpg';

export default function Pricing() {
  return (
    <section className='page_pricing'>
      <div className="page_wrapper">
        <h2 className='section_heading' id='PricingAnchor'>Oferta</h2>
        <ul className='pricing_list'>
          <li className='pricing_list--item'>
            <div className="image_container"></div>
            <img src={Gallery1} alt="" />
            <h4>PhysioKobido</h4>
            <p>
              Autorska metoda estetycznej terapii ciała i twarzy. Koncepcja indywidualnej pracy manualnej
              w obrębie zaburzonych tkanek w celu uzyskanie najlepszych efektów estetycznych i
              terapeutycznych.
            </p>
          </li>
          <li className='pricing_list--item'>
            <div className="image_container"></div>
            <img src={Gallery2} alt="" />
            <h4>Terapia szczekowo-twarzowa</h4>
            <p>
              Fizjoterapeutyczne wspomaganie leczenia ortodontycznego, stomatologicznego i terapii
              bruksizmu. Terapia zaburzeń i napięć obszaru twarzy (szczęki i żuchwy oraz stawów
              skroniowo-żuchwowych).
            </p>
          </li>
          <li className='pricing_list--item'>
            <div className="image_container"></div>
            <img src={Gallery3} alt="" />
            <h4>Fizjoterapia ogólna</h4>
            <p>
              Fizjoterapia zaburzeń narządu ruchu – schorzenia i bóle kręgosłupa, stawów, mięśni.
              Fizjoterapia w przebiegu urazów narządu ruchu.
            </p>
          </li>
        </ul>
      </div>
    </section>
  )
}
