import React from 'react';
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { useRef, useEffect } from 'react';

import '../styles/nav.scss'

import LogoImg from '../images/logo.png'

export default function Navbar() {
  const ref = useRef(null);

  useEffect(() => {
    const handleClick = event => {
      const element = ref.current;

      if (element.contains(event.target)) {
        // inside click
        const nav = document.querySelector('.main-nav-menu');
        nav.classList.toggle('active');
        element.classList.toggle('active');
      } else {
        // outside click
        const nav = document.querySelector('.main-nav-menu');
        nav.classList.remove('active');
        element.classList.remove('active');
      }
    };

    const element = ref.current;

    element.addEventListener('click', handleClick);

    return () => {
      element.removeEventListener('click', handleClick);
    };
  }, []);

  return (
    <nav className='main-nav'>
      <div className='page_wrapper navbar-wrapper'>
        <a className="navbar-brand" href="#">
          <img src={LogoImg} alt="" />
        </a>

        <div className='main-nav-menu'>
          <ul className='nav-contact-list'>
            <li>
              <span>Tel:</span>
              <a href="">+48 502 288 986</a>
            </li>
            <li>
              <span>Email:</span>
              <a href="">centrumyas@gmail.com</a>
            </li>
          </ul>

          <ul className="nav_list">
            <li className='nav-list-item'>
              <AnchorLink to="#AboutAnchor" className='nav-link' title="O nas">
                O nas
              </AnchorLink>
            </li>
            <li className='nav-list-item'>
              <AnchorLink to="#PricingAnchor" className='nav-link' title="Oferta">
                Oferta
              </AnchorLink>
            </li>
            <li className='nav-list-item'>
              <AnchorLink to="#ReviewsAnchor" className='nav-link' title="Opinie">
                Opinie
              </AnchorLink>
            </li>
            <li className='nav-list-item'>
              <AnchorLink to="#ContactAnchor" className='nav-link' title="Oferta">
                  Oferta
              </AnchorLink>
            </li>
          </ul>

        </div>

        <div ref={ref} id='burger-btn' className='burger-btn'>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </nav>
  )
}
