import React from 'react';

export default function Reviews() {
  return (
    <section className='page_reviews'>
      <div className="page_wrapper">
        <h2 className='section_heading' id='ReviewsAnchor'>Opinie</h2>
      </div>
    </section>
  )
}
