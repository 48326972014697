import React from 'react';
import { AnchorLink } from "gatsby-plugin-anchor-links";

import '../styles/header.scss'

export default function Header() {
  return (
    <section className='page_header'>
      <div className="page_wrapper">
        <h1>
          Centrum YAS to miejsce, w którym oferuję Pacjentom najskuteczniejsze formy terapii i
          najwyższą jakość usług, zgodnie z aktualnymi doniesieniami ze świata medycyny i sportu.
        </h1>

        <AnchorLink to="#AboutAnchor" title="O nas">
          <div className="btn btn-primary mt-20">O nas</div>
        </AnchorLink>
      </div>
    </section>
  )
}
